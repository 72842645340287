import { createStore } from 'redux'

const initialState = {
  sidebarShow: true,
  autenticated: localStorage.getItem('autenticated'),
  token: localStorage.getItem('token'),
  cnpj: localStorage.getItem('cnpj'),
  asideShow: false,
  theme: 'default',
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
